/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
import React, {
	useCallback, useEffect, useState,
} from "react";
import dynamic from "next/dynamic";
import {
	Axios,
	checkResponseOnIsr,
	homepageDetail,
} from "Helpers/utils";
import Env from "Consts/env";
import { useDispatch, useSelector } from "react-redux";
import ClientCookies from "js-cookie";
import vars from "Consts/vars";
import Loader from "@fdn/loader";
import { isFirefox, isMobile } from "react-device-detect";
import { wrapper } from "@/Store/index";
import { loadBanner } from "@/Store/banner_store/lib/actions";
import { loadWishlist } from "@/Store/wishlist_store/lib/actions";
import { loadCategory } from "@/Store/category_store/lib/actions";
import { loadCategoryDesktop } from "@/Store/category_desktop_store/lib/actions";
import { loadFlashSale } from "@/Store/flash_sale_store/lib/actions";

const CategoryPopup = dynamic(
	() => import("Components/category-popup"),
	{ ssr: false },
);

const AddToCartPopup = dynamic(
	() => import("Components/add-to-cart-popup"),
	{ ssr: false },
);

const Drawer = dynamic(
	() => import("Components/drawer"),
	{ ssr: true },
);

const ChildListProducts = dynamic(
	() => import("Containers/child-list-products"),
	{ ssr: true },
);

const LoadingSection = dynamic(
	() => import("Containers/loading-section"), { ssr: true },
);

const Navigation = dynamic(
	() => import("Components/navigation"),
	{ ssr: false },
);

const SectionList = dynamic(
	() => import("Containers/section-list"),
	{ ssr: false },
);

const Layout = dynamic(
	() => import("Containers/layout"),
	{ ssr: false },
);

const Section = dynamic(
	() => import("Containers/section"),
	{
		ssr: false,
		loading: () => <LoadingSection type="default" isMobile={isMobile} />,
	},
);

const BannerPopUp = dynamic(
	() => import("Components/banner-popup"),
	{ ssr: true },
);

const BannerSliderHome = dynamic(
	() => import("Containers/banner-slider-home"),
	{
		ssr: false,
		loading: () => <LoadingSection type="banner" isMobile={isMobile} />,
	},
);

const ChildDetailQuickReview = dynamic(
	() => import("Containers/child-detail-quick-review"),
	{ ssr: false },
);

const ChildListBrands = dynamic(
	() => import("Containers/child-list-brands"),
	{ ssr: false },
);

const ModalQuickReview = dynamic(
	() => import("Components/modal-quick-review"),
	{ ssr: false },
);

const Popup = dynamic(
	() => import("Components/pdp-popup"),
	{ ssr: false },
);

const Index = (props) => {

	const authorization = ClientCookies.get("token") || null;
	const usrname = ClientCookies.get("usrname") || null;
	const data = props;
	const dispatch = useDispatch();
	const getStoreSelf = useSelector((state) => (state.storeSelf));
	const getStoreBanner = useSelector((state) => (state.storeBanner));
	const getWishlist = useSelector((state) => (state.storeWishlist));
	const getMergeCart = useSelector((state) => (state.storeMergeCart.data));

	const getStoreCategory = useSelector((state) => {

		const categoryNavigation = [];
		const resStoreCategory = state.storeCategory.data && typeof state.storeCategory.data.data !== "undefined"
			? state.storeCategory.data
			: null;

		if (resStoreCategory?.data[0]?.children_data) {

			resStoreCategory.data[0].children_data.map((item) => {

				categoryNavigation.push({
					id: item.entity_id,
					name: item.category_name,
					slug: item.category_path,
					items: item.children_data.length > 0
						? item.children_data?.map((child) => ({
							id: child.entity_id,
							name: child.category_name,
							slug: child.category_path,
							items: child.children_data.length > 0
								? child.children_data?.map((childs) => ({
									id: childs.entity_id,
									name: childs.category_name,
									slug: childs.category_path,
								})) : [],
						})) : [],
				});

			});

		}

		return categoryNavigation;

	});

	const getStoreCategoryDesktop = useSelector((state) => {

		const categoryNavigationDesktop = [];
		const resStoreCategoryDesktop = state.storeCategoryDesktop.data && typeof state.storeCategoryDesktop.data.data !== "undefined"
			? state.storeCategoryDesktop.data
			: null;

		resStoreCategoryDesktop?.data[0]?.children_data?.filter((val) => val.include_in_menu === "1")?.map((item) => {

			categoryNavigationDesktop.push({
				id: item.entity_id,
				name: item.category_name,
				slug: item.category_path,
				items: item.children_data.length > 0
					? item.children_data?.map((child) => ({
						id: child.entity_id,
						name: child.category_name,
						slug: child.category_path,
						items: child.children_data.length > 0
							? child.children_data?.map((childs) => ({
								id: childs.entity_id,
								name: childs.category_name,
								slug: childs.category_path,
								items: childs.children_data.length > 0
									? childs.children_data?.map((lastChilds) => ({
										id: lastChilds.entity_id,
										name: lastChilds.category_name,
										slug: lastChilds.category_path,
									})) : [],
							})) : [],
					})) : [],
			});

		});
		categoryNavigationDesktop.splice(1, 0, {
			id: 200,
			name: "Brands",
			slug: "brands",
			items: [],
		});
		return categoryNavigationDesktop;

	});

	const [showCategory, setShowCategory] = useState(false);
	const [isShowModal, setShowModal] = useState(false);
	const [product, setProduct] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingBanner, setIsLoadingBanner] = useState(true);
	const [HomePageList, setHomePageList] = useState(data.storeListDataHomepage);
	const [HomePageListLoading, setHomePageListLoading] = useState(data.listSectionLoad);
	const [HomePageListErr, setHomePageListErr] = useState(data.listSectionErr);
	const [bannerPopup, setBannerPopup] = useState(null);
	const [addToCartLoading, setAddToCartLoading] = useState(false);
	const [addToCartPopupShow, setAddToCartPopupShow] = useState({
		show: false,
		addedProduct: {},
		productId: 0,
	});
	const [popupShow, setPopupShow] = useState({
		show: false,
		message: "",
		color: "#323942",
		redirect: false,
		closable: false,
	});

	const HandleClickNavigation = (val) => {

		if (val === "category") {

			setShowCategory(true);

		}

		if (val === "brand") {

			window.location.href = "/brands";

		}

		if (val === "home") {

			window.location.href = "/";

		}

		if (val === "user") {

			if (getStoreSelf?.data?.id) {

				window.location.href = `${process.env.NEXT_PUBLIC_SSO_DOMAIN}/user/${getStoreSelf.data.username}`;

			} else {

				window.location.href = process.env.NEXT_PUBLIC_SSO_DOMAIN;

			}

		}

	};

	const HandleQuickReview = async (params) => {

		setShowModal(true);
		setIsLoading(true);
		const resProduct = await Axios(`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-product/${params}`);

		if (resProduct?.data?.meta?.code === 200) {

			setProduct(resProduct.data.data?.product);
			setIsLoading(false);

		}

	};

	useEffect(async () => {

		await dispatch(
			loadBanner({
				payload: {
					placement: isMobile ? "mobile_home_bs" : "desktop_home_bs",
				},
			}),
		);

		const alrOpened = 	window.sessionStorage.getItem("opened-fds");

		if (!alrOpened) {

			try {

				setIsLoading(true);

				const placement = isMobile ? "FDS_home_mobile_popup" : "FDS_home_desktop_popup";
				const bannerPopUp = await Axios(`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-banner?placement=${placement}`, {
					headers: {
						...Env.HEADERS,
						key: process.env.NEXT_PUBLIC_KEY_API_CLIENT,
					},
				});
				const dataBanner = bannerPopUp?.data;

				if (dataBanner?.meta?.code === 200) {

					setIsLoading(false);
					setBannerPopup(dataBanner.data?.length > 0 ? dataBanner.data[0] : null);

				}
				setIsLoading(false);

			} catch (err) {

				setIsLoading(false);
				setBannerPopup(null);

			}

		}

	}, [isMobile]);

	useEffect(() => {

		const userId = ClientCookies.get("user_id") || null;
		if ((authorization && userId) || (authorization && getMergeCart?.data?.user_id)) {

			dispatch(
				loadWishlist({
					Authorization: authorization,
					userId: getMergeCart?.data?.user_id || userId,
				}),
			);

		}

	}, [authorization, getMergeCart?.data?.user_id]);

	const passStoreSelf = useCallback(() => {

		if (authorization && usrname) {

			return getStoreSelf;

		}
		return { status: vars.FETCH_RESULT };

	}, [getStoreSelf]);

	useEffect(() => {

		if (getStoreBanner.status === (vars.FETCH_RESULT || vars.FETCH_ERROR)) {

			setIsLoadingBanner(false);

		}

	}, [getStoreBanner.status]);

	const [fetchFSstatus, FSData] = useSelector((state) => [
		state.storeFlashSale.status, (state.storeFlashSale?.data
			&& state.storeFlashSale?.data?.data
			&& state.storeFlashSale?.data?.data[0]) || null]);

	const refetchListHomepage = async () => {

		await Promise.all(data.storeListDataHomepage?.map(async (item) => {

			const isFlashSaleSlide = item.endpoint?.toLowerCase().includes("flash_sale");
			const onSaleParam = item.endpoint?.split("?")[1];
			let dataTmp = { ...item };

			if (!item.data) {

				const items = {
					...item,
					endpoint: `/fds-product?${onSaleParam}`,
				};

				if (!isFlashSaleSlide) {

					await homepageDetail({
						...items,
						proxy: true,
					}).then((resp) => {

						if (resp && resp.status >= 200 && resp.status < 300 && resp.data) {

							dataTmp = { ...dataTmp, ...resp.data };
							setHomePageListErr((prev) => ({
								...prev,
								[item.tipe_data]: false,
							}));

						} else {

							setHomePageListErr((prev) => ({
								...prev,
								[item.tipe_data]: true,
							}));

						}

					}).catch(() => {

						dataTmp = { ...dataTmp };
						setHomePageListErr((prev) => ({
							...prev,
							[item.tipe_data]: true,
						}));

					}).finally(() => {

						if (item.tipe_data !== "flash_sale") {

							setHomePageListLoading((prev) => ({
								...prev,
								[item.tipe_data]: !!prev[item.tipe_data],
							}));

						}

					});

				} else {

					dispatch(loadFlashSale());

				}

			}

			return dataTmp;

		})).then((res) => {

			setHomePageList(res);

		}).catch(() => {

			setHomePageList([]);

		});

	};

	useEffect(() => {

		refetchListHomepage();

	}, []);

	useEffect(() => {

		if (fetchFSstatus === (vars.FETCH_RESULT)) {

			setHomePageListErr((prev) => ({
				...prev,
				flash_sale: false,
			}));
			setHomePageListLoading((prev) => ({
				...prev,
				flash_sale: false,
			}));

		} else if (fetchFSstatus === vars.FETCH_INIT || fetchFSstatus === vars.FETCH_LOADING) {

			setHomePageListErr((prev) => ({
				...prev,
				flash_sale: false,
			}));
			setHomePageListLoading((prev) => ({
				...prev,
				flash_sale: true,
			}));

		} else if (fetchFSstatus === vars.FETCH_ERROR) {

			setHomePageListErr((prev) => ({
				...prev,
				flash_sale: true,
			}));
			setHomePageListLoading((prev) => ({
				...prev,
				flash_sale: false,
			}));

		}

	}, [fetchFSstatus]);

	useEffect(() => {

		dispatch(
			loadCategoryDesktop({
				payload: {
				},
			}),
		);

		dispatch(
			loadCategory(),
		);

	}, []);

	return (
		<>
			{
				bannerPopup && (
					<BannerPopUp
						url={bannerPopup.url}
						name={bannerPopup.name}
						image={bannerPopup.image}
						description={bannerPopup.description}
						isMobile={isMobile}
					/>
				)
			}
			<Layout
				home
				title="Femaledaily"
				typeNavbarSecondary="type-3"
				titleNavbar="Situs Jual Beli Skincare dan Kosmetik Terlengkap"
				ismobile={isMobile}
				self={passStoreSelf()}
				categoryDesktop={getStoreCategoryDesktop}
				getQuoteIdErrMessage={null}
				metaTitle="Situs Jual Beli Skincare dan Kosmetik Terlengkap"
				isShowSecondaryNav={false}
			>
				{!isLoadingBanner && getStoreBanner.status === (vars.FETCH_RESULT || vars.FETCH_ERROR) ? (
					<BannerSliderHome isMobile={isMobile} data={getStoreBanner?.data?.data} />
				) : (
					<LoadingSection isMobile={isMobile} type="banner" />
				)}
				{
					data && HomePageList?.length > 0 && HomePageList?.map((item, idx) => {

						const title = item?.title;
						const tipeData = item?.tipe_data;
						const redirect = item?.has_more ? item?.web_view_all_url : null;
						const isWishlistActive = getWishlist.data?.data?.length > 0;
						const dataProduct = (isWishlistActive || (getStoreSelf?.data?.id)) && title === "For You" ? null
							: tipeData === "product_list" ? (item.data && item.data?.products?.slice(0, 6))
								: tipeData === "brand_campaign" ? (item.data && item.data[0]?.products?.slice(0, 6)) || (item.data && item.data?.products?.slice(0, 6))
									: tipeData === "flash_sale" ? (FSData && FSData.products)
										: item?.data;
						const isFlashSaleClosed = tipeData === "flash_sale" && (FSData && FSData?.category && FSData?.category[0] && (FSData?.category[0]?.flashsale_status === "closed"));
						const isFlashSaleSection = ((tipeData === "flash_sale") || (dataProduct?.length || 0) > 0);
						const isLoadingSection = (tipeData === "flash_sale" ? (HomePageListLoading.flash_sale && (fetchFSstatus === vars.FETCH_INIT || fetchFSstatus === vars.FETCH_LOADING)) : HomePageListLoading[tipeData]);

						return (
							isFlashSaleSection && (isFlashSaleClosed ? null
								: (
									<React.Fragment key={`${title}-${String(idx)}`}>
										<Section
											isLoadingSection={isLoadingSection}
											isErrorSection={HomePageListErr && HomePageListErr[tipeData]}
											handleRefetch={() => (tipeData === "flash_sale" ? dispatch(loadFlashSale()) : refetchListHomepage())}
											title={title}
											isMobile={isMobile}
											isMozilla={isFirefox}
											urlViewMore={redirect}
											color={item.color ?? "#DB264D"}
											background={tipeData === "flash_sale" ? "#F9FAFB" : item.background_color}
											type={
												tipeData === "flash_sale" ? "flash-sale"
													: tipeData === "brand_campaign" ? "brand-campaign"
														: isMobile && (tipeData === "collection" || tipeData === "flash_sale" || tipeData === "brand_campaign") ? "slider"
															: "default"
											}
											date={
												(tipeData === "flash_sale" && !HomePageListLoading[tipeData])
													? (FSData && FSData.category[0] && FSData.category[0].dateline)
													: null
											}
											banner={
												(tipeData === "flash_sale" && !HomePageListLoading[tipeData])
													? (FSData && FSData.category[0] && FSData.category[0].banner)
													: null
											}
											runText={tipeData !== "flash_sale" && item?.short_description?.toUpperCase()}
											description={tipeData !== "flash_sale" && item?.description}
											logoUrl={tipeData !== "flash_sale" && item?.image}
											flashSaleType={isLoadingSection ? "upcoming" : FSData?.category[0]?.flashsale_status}
											// flashSaleType="upcoming"
										>
											<SectionList
												isLoadingSection={isLoadingSection}
												type={tipeData}
												isMobile={isMobile}
												data={dataProduct}
												handleQuickReview={HandleQuickReview}
												isFlashSale={tipeData === "flash_sale"}
											/>

										</Section>
									</React.Fragment>
								))
						);

					})
				}
				{
					(((getStoreSelf.status === vars.FETCH_LOADING)
					|| (getStoreSelf.status === vars.FETCH_INIT)
					|| (getWishlist.status === vars.FETCH_LOADING)
					|| (getWishlist.status === vars.FETCH_INIT))
					&& authorization && !(getMergeCart?.meta?.code >= 200 || getMergeCart?.meta?.code < 300))
						? (
							<LoadingSection isMobile={isMobile} />
						) : (((getStoreSelf
							&& getStoreSelf.data?.id)
							&& (getWishlist && getWishlist.data?.data && getWishlist.data?.data?.length > 0))
							&& (
								<Section isMobile={isMobile} isMozilla={isFirefox} title="Your Wishlist" urlViewMore={`${process.env.NEXT_PUBLIC_SSO_DOMAIN}/user/wishlist`}>
									<ChildListProducts
										isMobile={isMobile}
										data={getWishlist && getWishlist.data && getWishlist.data?.data}
										handleQuickReview={HandleQuickReview}
									/>
								</Section>
							))
				}
				{data?.storeBrands?.data?.length > 0 && (
					<Section
						isMobile={isMobile}
						isMozilla={isFirefox}
						title="Featured Brands"
						urlViewMore="/brands"
						background="#F9FAFB"
					>
						<ChildListBrands
							isMobile={isMobile}
							data={data && data.storeBrands && data.storeBrands.data}
						/>
					</Section>
				)}
				<Navigation isMobile={isMobile} showCategory={showCategory} onClick={(value) => HandleClickNavigation(value)} active="home" />

				<CategoryPopup
					isMobile={isMobile}
					show={showCategory}
					data={getStoreCategory}
					onHide={() => setShowCategory(false)}
					destinationUrl={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/category`}
				/>
				<ModalQuickReview show={isShowModal} onClick={() => setShowModal(false)} closable>
					<ChildDetailQuickReview
						data={product}
						isLoading={isLoading}
						closeModal={() => setShowModal(false)}
						setPopupShow={(val) => setPopupShow(val)}
						setAddToCartLoading={(val) => setAddToCartLoading(val)}
						setAddToCartPopupShow={(val) => setAddToCartPopupShow(val)}
					/>
				</ModalQuickReview>

				{addToCartLoading && (
					<>
						<div className="fdn-loader">
							<Loader />
						</div>
						<style>
							{`
								.fdn-loader {
									position:absolute;
								}
							`}
						</style>
					</>
				)}

				<Popup
					isMobile={data?.isMobile}
					color={popupShow?.color}
					show={popupShow?.show}
					onHide={() => {

						setPopupShow(false);

						if (popupShow?.redirect) {

							window.location.href = `${process.env.NEXT_PUBLIC_SSO_DOMAIN}/my/account`;

						}

					}}
					closable={popupShow?.closable}
					text={popupShow?.message}
					withOk={false}
				/>

				{/* Drawer for add to cart configurable products */}
				<Drawer
					isMobile={isMobile}
					contentBackground="#FFF"
					title="Added to Cart!"
					headerIconSrc="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/fdn-studio-new/static/added-to-cart.png"
					onHide={() => setAddToCartPopupShow((prev) => ({
						show: false,
						addedProduct: prev.addedProduct,
						productId: prev.productId,
					}))}
					type="with-overlay"
					show={addToCartPopupShow?.show}
				>
					<AddToCartPopup
						isMobile={isMobile}
						addedProduct={addToCartPopupShow?.addedProduct}
						productId={addToCartPopupShow?.productId}
					/>
				</Drawer>
			</Layout>
		</>
	);

};

export const getStaticProps = wrapper.getStaticProps(
	async () => {

		const headers = { ...Env.HEADERS };
		const method = "GET";
		const options = { headers, method };

		const homepage = await fetch(`${process.env.NEXT_PUBLIC_MAGENTO_BS}/magento/homepage`, options);

		const products = await fetch(`${process.env.NEXT_PUBLIC_MAGENTO_BS}/magento/product?page=1&limit=5&brand=&parent_category=&category=for-you&sort=&placement=2&search_term=`, options);

		const brandsData = await fetch(`${process.env.NEXT_PUBLIC_MAGENTO_BS}/magento/brand/all?page=&initial=&sort=&limit=8&is_featured=1`, options);

		const getStoreHomepage = await checkResponseOnIsr(homepage);

		const getStoreProducts = await checkResponseOnIsr(products);

		let getStoreBrands = await checkResponseOnIsr(brandsData);

		getStoreBrands = {
			...(Object.keys(getStoreBrands?.data).length > 0 && {
				...getStoreBrands,
				data: [
					...getStoreBrands?.data?.slice(0, 8),
				],
			}),
		};

		let listDataHomepages = [];

		if (getStoreHomepage?.data?.length > 0) {

			listDataHomepages = await Promise.all(
				getStoreHomepage.data?.map(async (item) => {

					let data = { ...item };

					if (item) {

						if (!item.endpoint?.toLowerCase().includes("sale")) {

							await homepageDetail({
								...item,
							}).then((resp) => {

								if (resp && resp.status >= 200 && resp.status < 300 && resp.data) {

									data = { ...data, ...resp.data, error: false };

								} else {

									data = { ...data, data: null, error: true };

								}

							}).catch((err) => {

								console.log("MASOK error", err);

							});

						} else {

							data = { ...data, data: null, error: false };

						}

					}

					return data;

				}),
			);

		}
		const listSectionLoad = Object.assign(...listDataHomepages.map((item) => (
			{ [item.tipe_data]: !item.data })));
		const listSectionErr = Object.assign(...listDataHomepages.map((item) => (
			{ [item.tipe_data]: item.error !== undefined ? item.error : null })));

		return {
			props: {
				storeProducts: getStoreProducts || null,
				storeBrands: getStoreBrands || null,
				storeListDataHomepage: listDataHomepages || null,
				listSectionLoad: listSectionLoad || null,
				listSectionErr: listSectionErr || null,
			}, // will be passed to the page component as props
			revalidate: 10,
		};

	},
);

export default Index;
